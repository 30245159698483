import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import BackButton from '../components/back-button.js'
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image";
import PropTypes from "prop-types";
import { MDXRenderer } from "gatsby-plugin-mdx"


const Template = ({ data: { mdx: { body, frontmatter: { title, teaser, keywords, featuredImage } } } }) => {
  const featuredImg = featuredImage?.childImageSharp?.gatsbyImageData

  return (
    <Layout>
      <SEO
        keywords={(keywords?.split(',') || []).map(x => x.trim())}
        title={title}
        description={teaser}
      />
      <BackButton />
      { featuredImg ? <GatsbyImage image={{ ...featuredImg, aspectRatio: 3 }} className='mt-8' /> : null }
      
      <h1 dangerouslySetInnerHTML={{ __html: title }} />

      <MDXRenderer>{ body }</MDXRenderer>

    </Layout>
  );
}

Template.propTypes = {
    data: PropTypes.object
};


export default Template


export const pageQuery = graphql`
  query($slug: String!) {
    mdx(frontmatter: {slug: { eq: $slug } }) {
      id
      body
      frontmatter {
        slug
        title
        keywords
        teaser
        featuredImage {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
      body
    }
  }
`
