import React from 'react'
// import PropTypes from 'prop-types'
import { Link } from 'gatsby'

const BackButton = ()  =>  (
  <Link to='..'>
    <button className='transition duration-700 ease-in-out border px-4 py-2 rounded-full border-qblue font-bold hover:bg-qblue hover:text-white'>
      Zurück
    </button>
    {/* <div className='flex'>
      <div className='h-16 mr-16'>
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><circle cx="12" cy="12" r="10"></circle><polyline points="12 8 8 12 12 16"></polyline><line x1="16" y1="12" x2="8" y2="12"></line></svg>
      </div>
      Zurück
   </div> */}
  </Link>
)

BackButton.propTypes = {

};

export default BackButton
